<template>
  <!-- 
    画面: 部屋タイプ新規登録
    用途: 部屋タイプを新規登録する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <strong class="card-title">{{ $t("roomTypeCreatePage.pageTitle") }}</strong>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">
              
              <!-- #region 識別子 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("roomTypeCreatePage.identifier") }}</strong></CCol>
                <CCol md="2">
                  <CInput v-model="edit.identifier" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.identifier.$dirty && !$v.edit.identifier.required"
                  >
                    {{ $t("validations.required", { vName: $t("roomTypeCreatePage.identifier") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 識別子 -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("roomTypeCreatePage.name") }}</strong></CCol>
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{ $t("validations.required", { vName: $t("roomTypeCreatePage.name") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 定員 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("roomTypeCreatePage.capacity") }}</strong></CCol>
                <CCol md="1">
                  <CInput
                    v-model="edit.min"
                    type="number"
                  />
                </CCol>
                ~
                <CCol md="1">
                  <CInput
                    v-model="edit.max"
                    type="number"
                  />
                </CCol>
                {{ $t("common.personUnit") }}
              </CRow>

              <!-- #region Validation Error -->
              <CRow>
                <CCol class="label"></CCol>
                <CCol>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.min.$dirty && !$v.edit.min.required"
                  >
                    {{ $t("validations.required", { vName: $t("roomTypeCreatePage.capacityMin") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.max.$dirty && !$v.edit.max.required"
                  >
                    {{ $t("validations.required", { vName: $t("roomTypeCreatePage.capacityMax") })}}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.max.$dirty && !$v.edit.max.minValue"
                  >
                    {{ $t("validations.minValue", { vName: $t("roomTypeCreatePage.capacityMax"), vMin: `${edit.min}名` })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion Validation Error -->
              <!-- #endregion 定員 -->

              <!-- #region 基準在庫数 -->
              <CRow>
                <CCol class="label"><strong class="required-mark">{{ $t("roomTypeCreatePage.baseInventory") }}</strong></CCol>
                <CCol md="1">
                  <CInput
                    v-model="edit.inventory"
                    type="number"
                  />
                </CCol>
              </CRow>

              <!-- #region Validation Error -->
              <CRow>
                <CCol class="label"></CCol>
                <CCol>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.inventory.$dirty && !$v.edit.inventory.required"
                  >
                    {{ $t("validations.required", { vName: $t("roomTypeCreatePage.baseInventory") })}}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion Validation Error -->
              <!-- #endregion 基準在庫数 -->

              <!-- #region 部屋の広さ -->
              <CRow>
                <CCol class="label"><strong>{{ $t("roomTypeCreatePage.size") }}</strong></CCol>
                <CCol md="1">
                  <CInput
                    v-model="edit.size"
                    type="number"
                  />
                </CCol>
                <CCol md="2">
                  <CSelect
                    :value.sync="edit.sizeUnit"
                    :options="RoomFloorTypeSelection"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 部屋の広さ -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"><strong>{{ $t("roomTypeCreatePage.description") }}</strong></CCol>
                <CCol>
                  <CTextarea
                    class="mb-2"
                    rows="4"
                    v-model="edit.description"
                  />
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #region 確認ボタン -->
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="onCheckClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
              <!-- #endregion 確認ボタン -->

            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <!-- #region Check -->
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">

        <!-- #region 識別子 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("roomTypeCreatePage.identifier") }}</strong></CCol>
          <CCol><span> {{ edit.identifier }} </span></CCol>
        </CRow>
        <!-- #endregion 識別子 -->

        <!-- #region 名称 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("roomTypeCreatePage.name") }}</strong></CCol>
          <CCol><span> {{ edit.name }} </span></CCol>
        </CRow>
        <!-- #endregion 名称 -->

        <!-- #region 定員 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("roomTypeCreatePage.capacity") }}</strong></CCol>
          <CCol><span> {{ edit.min | personCapacity(edit.max) }} </span></CCol>
        </CRow>
        <!-- #endregion 定員 -->

        <!-- #region 基準在庫数 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("roomTypeCreatePage.baseInventory") }}</strong></CCol>
          <CCol><span> {{ edit.inventory }} </span></CCol>
        </CRow>
        <!-- #endregion 基準在庫数 -->

        <!-- #region 部屋の広さ -->
        <CRow>
          <CCol class="label"><strong>{{ $t("roomTypeCreatePage.size") }}</strong></CCol>
          <CCol><span> {{ edit.size }} {{ edit.sizeUnit | roomFloorTypeLabel }} </span></CCol>
        </CRow>
        <!-- #endregion 部屋の広さ -->

        <!-- #region 説明 -->
        <CRow>
          <CCol class="label"><strong>{{ $t("roomTypeCreatePage.description") }}</strong></CCol>
          <CCol><span> {{ edit.description }} </span></CCol>
        </CRow>
        <!-- #endregion 説明 -->

      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton
          @click="checkModal = false"
          color="secondary"
        >
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton
          color="success"
          @click="onRegisterClicked()"
        >
          <i class="icon cil-save mr-1"></i> {{
          $t("common.register")
        }}
        </CButton>
      </template>
    </CModal>
    <!-- #endregion Check -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
// import i18n from "@/i18n";
import axios from "axios";
import RoomFloorTypeSelection from "@/mixins/property";

import {
  required,
  minValue,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "RoomTypeCreate",

  mixins: [RoomFloorTypeSelection],

  components: {},

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    uid() {
      return this.$store.state.uniqueId;
    },
  },

  watch: {
    successModal(newValue, oldValue) {
      // SuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      checkModal: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Edit
      edit: {
        hotelId: "", // TODO: HotelRoomController API 実装後、hotelIdは不要になる想定
        identifier: "",
        name: "",
        min: null,
        max: null,
        inventory: null,
        description: "",
        images: [],
        size: "10",
        sizeUnit: "m2",
      },
      //#endregion Edit


      //#region Message
      errorMessage: "",
      //#endregion Message
    };
  },

  validations() {
    return {
      edit: {
        identifier: { required },
        name: { required },
        min: { required },
        max: { required, minValue: minValue(this.edit.min) },
        inventory: { required },
        description: {},
      },
    };
  },

  methods: {
    //#region Event
    onCheckClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : (this.checkModal = true);
    },

    onRegisterClicked() {
      this.reqPost();
    },

    onBackClicked() {
      this.backPage();
    },
    //#endregion Event


    //#region Request
    reqPost() {
      this.loading = true;
      this.edit.hotelId = this.uid;
      const url = `/rest/hotel/${this.uid}/room`;
      const body = this.edit;

      axios
        .post(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log("resp.data" + JSON.stringify(resp.data));

          this.loading = false;
          this.checkModal = false;
          this.successModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.errorMessage = err;
          this.errorModal = true;
        });
    },
    //#endregion Request


    //#region Method
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },
};
</script>
